.loading{
    width:100%;
    height: 84vh;
    align-items: center;
}
.center{
    left: 50%;
    line-height: 200px;
    margin: auto;
    margin-top: -100px;
    position: absolute;
    top: 50%;
}
.down{
    color:red;
    left: 50%;
    top:60%;
}
.none{
    display: none;
}
.photo{
    width: 120px;
    height: 120px;
}
.preview-box{
    border-radius: 20px;
    background-color: #efefef;
    padding: 9px;
    width: 153px;
    margin: 5px;
}
.i-cross{
    position: absolute;
    color: red;
    margin-left: 6px;
}
/* .noLinkColor a,.noLinkColor{
    color: #2e2d2d;;
}
.noLinkColor a:hover,.noLinkColor:hover {
    color:red;
} */


.selected-img{
    border-color: black;
    border-radius: 10px;
    border-style: inset;
}